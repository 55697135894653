/** @jsx jsx */
import { jsx } from "theme-ui";
import useAuth from "gatsby-plugin-hfn-profile/auth/useAuth";
import DLWLayout from "../../../layouts/dlw-mobile";

import OverseasSecureVault from "../../../components/OverseasSecureVault";

function SpecialExercise() {
  const { srcmProfile } = useAuth();
  const { userFirebaseUid } = srcmProfile || {};

  return (
    <DLWLayout>
      <OverseasSecureVault uid={userFirebaseUid} />
    </DLWLayout>
  );
}

export default SpecialExercise;
