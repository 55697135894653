/** @jsx jsx */
import { jsx } from "theme-ui";

import useAuth from "gatsby-plugin-hfn-profile/auth/useAuth";
import MyStayRecords from "./MyStayRecords";

function OverseasSecureVault() {
  const { srcmProfile } = useAuth();
  const { userFirebaseUid: uid } = srcmProfile || {};
  if (!uid) {
    return "Invalid Configuration.  Please contact website administrator!";
  }
  return <MyStayRecords uid={uid} defaults={srcmProfile} />;
}

export default OverseasSecureVault;
